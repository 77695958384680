import React, { useState } from 'react';

function ScrollTopButton() {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Attach scroll event listener to window
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleVisibility);
  }

  return (
    <button
      className={`${
        visible ? 'opacity-100' : 'opacity-0'
      } fixed bottom-14 right-5 z-10 transition-opacity duration-300 ease-in-out rounded-full p-2 bg-blue-900 text-white hover:bg-blue-700`}
      onClick={scrollToTop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
      </svg>
    </button>
  );
}

export default ScrollTopButton;
