import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


function EbAccounting() {
  return (
    <div className=" ">
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out  inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/Products/accounting-pc.png"
            alt="About"
          />

        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">

            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              EasyBooks Accounting

            </h2>
            <Link to='/'>
              Home
            </Link>
            /Accounting
            <br />
            <br />
            <Link to="/Contact" className="justify-self-center">
              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                Contact Us <i className="far fa-paper-plane"></i></button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between  mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="w-fullflex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left underline underline-offset-4">EasyBooks Accounting Software</h1>


          <p className="font-normal text-base leading-6 text-gray-600 text-left">
            EasyBooks Accounting is a simple to use, affordable, Secure web-based Accounting Package developed to manage financial data for any Small and medium sized institution (Churches, Schools, Social Enterprises, Business Enterprises, NGOs etc.). The system can be run both on-premise and online.

            It is an Off-the-shelf software but can be customized to suit any organization’s accounting requirements, including building new features that are not in the current version of the system. EasyBooks Accounting software can also be used on SaaS (Software as a Service) basis. In this arrangement, you just need to pay at the time of use, or you can share your raw data to us, and we will be able process it for you.

            With EasyBooks Accounting, you can manage multiple companies with each having its own data file.</p>
        </div>
      </div>


      <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="w-full lg:w-8/10 ">
          <img className='rounded' src="Assets/eb-accounting.webp" alt="Software" />
        </div>
        <div className="flex  flex-col justify-between gap-1 pt-12 mt-0 md:py-4 md:px-18 py-9 px-4">
          <div className="w-full lg:w-5/20 flex flex-col justify-center ">
            <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-0">Why EasyBooks Accounting?</h1>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              User-Friendly
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks Accounting is very user-friendly and
                very simple to use. Giving you a direct and easy
                to use interface. One does not require to have
                any prior knowledge in using any accounting
                package.
              </li>
            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">

              Affordable & Multi-Industrial

            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks Accounting Software is affordable
                ensuring that all enterprises have an opportunity to
                leverage the power of technology to propel their
                growth. Additionally our accounting software is a
                multi-industrial system and can be used in other
                non-conversional industries such as: Production,
                Construction & Health Care facilities among others.
              </li>

            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Platform Independent
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks is a web-based system giving you the
                freedom to either run it online or on-premise.
                Be in the know-how of your organization's
                financial position while in the comfort of your
                homes.

              </li>

            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Budget Monitoring and Evaluation
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Make a flexible budget of your Revenue and
                Expense forecast and generate reports to see
                whether you are on track with your planning or
                not. You can make; Weekly, Monthly, Quarterly,
                Semi-Annually or Annual Budgets
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Customizable                                                             </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                We want all your Accounting Workflows to be
                automated, if there a need, EasyBooks
                Accounting can be customized/tailored to 100%
                fit your organization. EasyBooks Accounting
                grows with you!
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Secure with 24/7 System Support
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks team is readily available for any support
                needs you may need ensuring that your operations
                are never affected. EasyBooks gives you peace of
                mind with its state-of-the-art security features.
                From Custom System Access Rights, Password
                Authentication, Two-Factor Authentication to Bio
                lock authentication.
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">

        <div className="w-full lg:w-2/6 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Innovate with Us</h1>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-money-bill"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Cost-effectiveness
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-screwdriver-wrench"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Maintenance and support</h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-timeline"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Secure</h4>
                </div>
              </div>
            </li>
          </ul>

          <br />
          <Link to="/Contact" className="justify-self-center ml-10">

            <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
              Start Now <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>


        </div>

        <div className="w-full lg:w-2/6  justify-left ">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/accounting-vector.jpg' alt='dev' />

        </div>
      </div>
      <Footer />
    </div>

  )
}

export default EbAccounting