import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function Clients() {
  return (
    <div>
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className="animate-pulse animate-once animate-ease-out  inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full "
            src="Assets/Clients/clients.jpg"
            alt="About"
          />

        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">

            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Our Clients

            </h2>
            <Link to='/'>
              Home
            </Link>
            <span>

              /Clients
            </span>
            <br />
            <br />
            <Link to="/Contact" className="justify-self-center">

              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                Contact Us <i className="far fa-paper-plane"></i></button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="w-fullflex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left">Clientele</h1>

          <p className="font-normal text-base leading-6 text-gray-600 text-left">Welcome to our client section! At EasyBooks Solutions, we prioritize our clients and strive to provide exceptional services to meet your needs. Our team is dedicated to ensuring your satisfaction and delivering results that exceed your expectations.
            <br />
            <br />
            As a valued client, you will have access to our team of experts who are dedicated to helping you achieve your goals. We believe in working closely with our clients to understand their needs and develop customized solutions that meet their unique requirements.</p>
        </div>

      </div>

      <div className="flex lg:flex-row flex-row justify-between gap-8 pt-12 mt-18 md:py-12 md:px-48 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Ministry of Health.png' alt='Client' />
        </div>

        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Clinton_Health_Access_Initiative.png ' alt='Client' />
        </div>

        <div className="shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/ALIDZIWA-WEB-LOGO.png' alt='Client' />
        </div>

        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/BMP.png' alt='Client' />
        </div>

      </div>

      <div className="flex lg:flex-row flex-row justify-between gap-8 pt-12 mt-18 md:py-12 md:px-48 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/MEGA-LOGO.png' alt='Client' />
        </div>

        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/maranatha.jpg' alt='Client' />
        </div>
        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Atsikana Pa Ulendo.png' alt='Client' />
        </div>


        <div className="shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Lake Malawi Anglican Univ.png' alt='Client' />
        </div>

      </div>

      <div className="flex lg:flex-row flex-row justify-between gap-8 pt-12 mt-18 md:py-12 md:px-48 py-9 px-4 animate-fade-up animate-once animate-ease-out">

      <div className="shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Speed Fitment.jpg' alt='Client' />
        </div>

        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Primary Medical.png' alt='Client' />
        </div>



        <div className=" shadow-lg w-full lg:w-2/6 flex flex-col justify-center ">
          <img src='Assets/Clients/Agro Input Suppliers.png' alt='Client' />
        </div>



      </div>
      <Footer />
    </div>
  )
}

export default Clients