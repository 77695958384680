import React, { useState, useEffect } from "react";

function App() {
  const slides = [
    {
      url: 'Assets/web_photo/Satellite-Ent-Photos-20.jpg',
    },
    {
      url: 'Assets/web_photo/Satellite-Ent-Photos-21.jpg',
    },
    {
      url: 'Assets/web_photo/Satellite-Ent-Photos-35.jpg',
    },   

  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide) =>
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);
  

  return (
      <>
      
      
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
            <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
           <img alt={''}
            style={{ backgroundImage: `url(${slides[currentSlide].url})` }}
            className='object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full 
            bg-cover duration-500'
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out ">
         
            <h2 className="  mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              EasyBooks Solutions
      
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
            Simplifying Business Operations
            <br/>
            
             through ICT Innovations.
            </p>
          
          </div>
        </div>
      </div>
      </>
    );
  };
  


export default App