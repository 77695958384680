import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function SoftwareDevelopment() {
  return (
    <div className=" ">
   <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/website-concept.jpg"
            alt="About"
          />
  
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl animate-fade-up animate-once animate-ease-out ">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
         
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
             Software Development
      
            </h2>
         <Link to='/'>
            Home
         </Link>
         /Software Development
         <br/>
         <br/>
         <Link to ="/Contact" className="justify-self-center">

<button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
Contact Us <i className="far fa-paper-plane"></i>
</button>  
</Link>
          </div>
        </div>
      </div>
      
    <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-10 md:px-20 py-9  px-4 animate-fade-up animate-once animate-ease-out ">

        <div className="w-fullflex flex-col justify-left">
            <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left ">
            Leverage our expertise to speed up your digital transformation
                </h1>

            <p className="font-normal text-base leading-6 text-gray-600 text-left">
            We are ready for challenges of any scale and complexity. 
            Whether you need a robust web solution able to support hundreds of thousands of concurrent users,
             or an advanced mobile app set to transform customer experience, we’ve got your back.   
           </p>
        </div>
    </div>  
    <div className="flex lg:flex-row flex-col justify-between gap-2 pt-12 mt-18 md:py-12 md:px-32 py-9 px-4 animate-fade-up animate-once animate-ease-out ">
    <div className="w-full lg:w-5/10 flex flex-col justify-center ">
            <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Solutions We Offer</h1>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-gray-800 pb-4">
            You have an idea for your next software endeavor — we have cross-functional experts to deliver.
            </h2>  
              <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Web Application Development
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
              We deliver enterprise-grade web solutions that power mission-critical workflows
              </li>
            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
            Mobile App Development
                        </h2>
      <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
              Delivering Stellar Mobile Experiences at Scale of Innovative mobile apps your users will love              </li>
            </ul>     
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
            Cloud Hosting Services
                        </h2>
      <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
              Secure Cloud Storage services             </li>
            </ul>       
            
                    </div>    <div className="justify-self-center w-full lg:w-3/6 flex flex-col">
     <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/mail.jpg' alt='dev'/>   
            
                    </div>

       
    </div> 
    <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">
                  
    <div className="w-full lg:w-2/6 flex flex-col justify-center ">
            <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Why Us?</h1>
            <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                        Secure and Carefully crafted components
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">Amazing Code Base</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">Dynamic components</h4>
                      </div>
                    </div>
                  </li>
                </ul>

                <br/>
         <Link to="/Contact" className="justify-self-center ml-10">

<button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
Start Now <i className="fa-solid fa-arrow-right"></i>
</button>  
</Link>
  
            
                    </div>    

                    <div className="w-full lg:w-2/6  justify-left ">
     <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/dev1.jpg' alt='dev'/>   
            
                    </div>
    </div>
    <Footer />
</div>  )
}

export default SoftwareDevelopment