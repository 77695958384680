import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom'


function EbERP() {
  return (
    <div className=" ">
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out  inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/Products/automation-pc.png"
            alt="About"
          />

        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">

            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              EasyBooks Enterprise Resource Planning
            </h2>
            <Link to='/'>
              Home
            </Link>
            /Enterprise Resource Planning
            <br />
            <br />
            <Link to="/Contact" className="justify-self-center">
              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                Contact Us <i className="far fa-paper-plane"></i></button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
        <div className="w-full flex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left underline underline-offset-4">EasyBooks Enterprise Resource Planning Software</h1>

          <p className="font-normal text-base leading-6 text-gray-600 text-left">
            EasyBooks Enterprise Resource Planning Software is a cutting-edge solution designed to streamline and simplify your business processes. With a focus on efficiency and productivity, our software empowers organizations to automate repetitive tasks, reduce manual errors, and enhance overall operational effectiveness.
            Whether you're a small startup or a large enterprise, EasyBooks Enterprise Resource Planning Software is tailored to meet your unique business requirements. Experience the transformative power of automation with EasyBooks today!
          </p>
        </div>
      </div>


      <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="w-full lg:w-8/10 ">
          <img className='rounded' src="Assets/eb-automation.png" alt="Software" />
        </div>
        <div className="flex  flex-col justify-between gap-1 pt-12 mt-0 md:py-4 md:px-18 py-9 px-4">
          <div className="w-full lg:w-5/20 flex flex-col justify-center ">
            <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-0">Why EasyBooks ERP?</h1>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Budget and Budgetary Control
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Enjoy seamless budgeting via importable Excel
                budget templates. NGOS can import work plan
                budgets (Grants, Projects, Objectives/Thematic
                Areas) into EasyBooks at once. Track budgets
                with expenditure analytics and multi-approval
                levels for over-spends.
              </li>
            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Payments Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Get seamless payments processing via
                integrated [Host to Host] connections to
                financial institutions and mobile money
                platforms for hassle-free payments to suppliers
                and employees. Pay your meeting participants
                with ease with no need for carrying bulky cash
                for allowances. (DSAs etc.)
              </li>

            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Finance, Accounting & Audit Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks manages shared costs, asset allocation,
                and tracks expenditures, enhancing financial
                transparency. Robust audit management ensures
                compliance with industry standards and
                regulations, with source documents just a click
                away.
              </li>

            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Human Resource & Payroll Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Streamline HR and payroll, simplify employee file
                management, and ensure timely and accurate
                processing. No more manual: Leave, Loan, Salary
                Advance, Overtime, Timesheets, Activity Budget
                Request, Advance Liquidation, etc? Migrate to
                EasyBooks. Go paperless. Go digital.
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Procurement Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Digitize all procurement needs with EasyBooks,
                from policies to supplier management, RFP/RFQ,
                Contract, Purchase Order, Invoices, and delivery
                notes. Access a supplier portal for seamless
                interaction. Say goodbye to emails and paper for
                supplier communication. Manage inventory in defined cost centers and warehouses.
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Grant/Project Management (NGOs)
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Take control with comprehensive grant and project
                management features. Including work plan budgeting,
                self-service for activity requests and liquidation,
                replacing email or manual processes. Includes:
                Cash Advance, Fuel Request, Conference and
                Accommodation Booking Requests, Procurement
                Requests with approval levels and policies.
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">

        <div className="w-full lg:w-2/6 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Innovate with Us</h1>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-money-bill"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Cost-effectiveness
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-screwdriver-wrench"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Maintenance and support</h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-timeline"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Secure</h4>
                </div>
              </div>
            </li>
          </ul>

          <br />
          <Link to="/Contact" className="justify-self-center ml-10">

            <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
              Start Now <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>


        </div>

        <div className="w-full lg:w-2/6  justify-left ">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Talk.jpg' alt='dev' />

        </div>
      </div>
      <Footer />
    </div>

  )
}

export default EbERP