import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom'

function BRP() {
  return (
    <div className=" ">
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/Consult.jpg"
            alt="About"
          />

        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl animate-fade-up animate-once animate-ease-out ">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">

            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Business Process
              Reengineering

            </h2>
            <Link  to='/'>
              Home
            </Link>
            /Business Process Reengineering
            <br />
            <br />
            <Link to="/Contact" className="justify-self-center">

              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                Contact Us <i className="far fa-paper-plane"></i>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-10 md:px-20 py-9  px-4 animate-fade-up animate-once animate-ease-out ">

        <div className="w-fullflex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left ">
            Unlocking Efficiency Through Business Process Re-Engineering (BPR).
          </h1>

          <p className="font-normal text-base leading-6 text-gray-600 text-left">
            Elevate your organization to new heights through Business Process Reengineering (BPR). BPR is not just a methodology; it's a strategic overhaul that transforms how businesses function. With a focus on achieving substantial improvements in efficiency and performance, BPR is the catalyst for innovation and operational excellence.
            In a rapidly evolving business landscape, embracing BPR is the key to staying ahead. It involves a meticulous examination of existing processes, discarding outdated practices, and integrating cutting-edge solutions. The result? Streamlined operations, heightened productivity, and a future-ready framework that positions your business for sustained success.
          </p>

        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-between gap-2 pt-12 mt-18 md:py-12 md:px-32 py-9 px-4 animate-fade-up animate-once animate-ease-out ">
        <div className="w-full lg:w-5/10 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Find the right fit for you</h1>
          <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
            Expertise
          </h2>
          <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
            <li>
              Our professionals specialize in BPR methodologies, driving significant improvements in operational workflows.
            </li>
            <li>
              We have a proven track record of designing and implementing BPR strategies for streamlined and efficient processes.
            </li>
          </ul>
          <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
            Innovation
          </h2>
          <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
            <li>
              We are at the forefront of innovation in the technology industry, and are always exploring new and emerging trends and technologies. </li>

            <li>
              Helping businesses adopt new tools and approaches that improve efficiency, reduce costs, and increase revenue.
            </li>
          </ul>

          <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
            Customization
          </h2>
          <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
            <li>
              We  understand the unique needs and requirements of our customers.             </li>
            <li>
              We provide ongoing maintenance and support services to ensure that their clients'
              software systems remain up-to-date and continue to function smoothly.
            </li>
          </ul>

        </div>    <div className="justify-self-center w-full lg:w-3/6 flex flex-col">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Fit.jpg' alt='dev' />

        </div>


      </div>
      <div className="flex lg:flex-row flex-col justify-between gap-2 pt-12 mt-18 md:py-12 md:px-32 py-9 px-4 animate-fade-up animate-once animate-ease-out ">
      <div className="justify-self-center w-full lg:w-3/6 flex flex-col">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Exp.jpg' alt='dev' />

        </div>

        <div className="w-full lg:w-5/10 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">We are Solution Oriented</h1>
          <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
            <li> Clients to take an active role in the problem-solving process.
              Rather than simply offering advice or recommendations,
              working closely with clients to identify the underlying causes of their problems,
              and then develop solutions that are tailored to their specific situation.</li>
          </ul>


        </div> 

      </div>
      <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">

        <div className="w-full lg:w-2/6 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Innovate with Us</h1>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-money-bill"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Cost-effectiveness
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-screwdriver-wrench"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Maintenance and support</h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-timeline"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Project management</h4>
                </div>
              </div>
            </li>
          </ul>

          <br />
          <Link to="/Contact" className="justify-self-center ml-10">

            <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
              Start Now <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>


        </div>

        <div className="w-full lg:w-2/6  justify-left ">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Talk.jpg' alt='dev' />

        </div>
      </div>

   
      <Footer />
    </div>
  )
}

export default BRP