import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom'


function EbIMERIS() {
  return (
    <div className=" ">
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className=" animate-pulse animate-once animate-ease-out  inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/Products/Imeris-pc.png"
            alt="About"
          />

        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">

            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              EasyBooks IMERIS

            </h2>
            <Link to='/'>
              Home
            </Link>
            /IMERIS
            <br />
            <br />
            <Link to="/Contact" className="justify-self-center">
              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                Contact Us <i className="far fa-paper-plane"></i></button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
        <div className="w-full flex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left underline underline-offset-4">EasyBooks Intergrated Medical Electronic Information System</h1>

          <p className="font-normal text-base leading-6 text-gray-600 text-left">
            EasyBooks IMERIS is a holistic healthcare ecosystem
            that redefines the way you manage patient
            information and deliver care. Our solution
            seamlessly integrates electronic health records
            (EHR), medical billing, Accounting Management,
            Payroll Management, and more into a unified system.
          </p>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">

        <div className="w-full lg:w-8/10 ">
          <img className='rounded' src="Assets/eb-imeris.png" alt="Software" />
        </div>
        <div className="flex  flex-col justify-between gap-1 pt-12 mt-0 md:py-4 md:px-18 py-9 px-4">
          <div className="w-full lg:w-5/20 flex flex-col justify-center ">
            <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-0">Why EasyBooks IMERIS?</h1>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Streamlined Clinical Workflows
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Our solution optimizes clinical workflows,
                reducing administrative burdens and allowing
                healthcare providers to focus on what matters
                most—patient care. With intuitive interfaces,
                easy navigation, and smart automation, you can
                expedite patient encounters, enhance accuracy,
                and improve overall clinical productivity.
              </li>
            </ul>

            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Monitoring and Evaluation
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Make a flexible budget of your Revenue and Expense forecast and generate reports
                to see whether you are on tract or not.
                You can make; Weekly, Monthly, Quarterly, Semi-Annually or Annual Budgets
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Comprehensive Patient Records
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks IMERIS consolidates patient data into a
                secure, easily accessible electronic format. From
                medical history and treatment plans to lab results
                and medication records. All information at your
                fingertips. Our solution enables a comprehensive
                view of each patient's health, ensuring informed
                decision-making and personalized care.
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Medical Billing and Revenue Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                Take control of the financial health of your
                practice. EasyBooks IMERIS simplifies medical
                billing processes, from claim creation and
                submission to tracking and reimbursement. Our
                solution helps you reduce claim denials, accelerate
                revenue cycles, and improve cash flow.
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Inventory, Accounting & Payroll Management
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks IMERIS is automatically integrated with
                an inventory module to ensure that there is order in
                how health facility pharmaceuticals and laboratories
                are being used. With auto-reminders on inventory
                levels, fast moving items, etc. Ensuring you stay on
                top of your health facility. There is also full
                accounting & payroll management at your finger tips.
              </li>

            </ul>
            <h2 className="text-1xl lg:text-1xl font-bold leading-9 text-blue-800 pb-4">
              Enhanced Compliance & Security
            </h2>
            <ul className="font-normal text-base leading-6 text-gray-600 md:px-6 list-disc">
              <li>
                EasyBooks IMERIS adheres to the highest industry
                standards to protect patient information. Robust
                security measures and access controls ensure that
                patient data remains confidential and secure.
                From Custom System Access Rights, Password
                Authentication, Two-Factor and Bio lock
                Authentication ensuring only authorized access.
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">

        <div className="w-full lg:w-2/6 flex flex-col justify-center ">
          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Innovate with Us</h1>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-money-bill"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Cost-effectiveness
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-screwdriver-wrench"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Maintenance and support</h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                    <i className="fa-solid fa-timeline"></i>
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">Secure</h4>
                </div>
              </div>
            </li>
          </ul>

          <br />
          <Link to="/Contact" className="justify-self-center ml-10">

            <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
              Start Now <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>


        </div>

        <div className="w-full lg:w-2/6  justify-left ">
          <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/doctor-vector.jpg' alt='dev' />

        </div>
      </div>
      <Footer />
    </div>

  )
}

export default EbIMERIS