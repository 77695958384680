import React from 'react'
import Herosection from '../Components/Herosection'
import Content from '../Components/Content'
import Footer from '../Components/footer'

function Home() {
  return (
    <div>
      <Herosection />
      <Content />
      <Footer />
    </div>
  )
}

export default Home