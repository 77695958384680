import React, { useState } from 'react';
import Footer from '../Components/footer';
import emailjs from '@emailjs/browser';

function Contact() {
  const [formState, setFormState] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    organisation: '',
    message: '',
    errors: {
      fullName: '',
      phoneNumber: '',
      email: '',
      organisation: '',
      message: '',
    },
    successMessage: '',
  });

  const validateForm = () => {
    const errors = {
      fullName: formState.fullName.trim() === '' ? 'Full Name is required' : '',
      phoneNumber: formState.phoneNumber.trim() === '' ? 'Phone Number is required' : '',
      email: !/^\S+@\S+\.\S+$/.test(formState.email) ? 'Invalid email address' : '',
      organisation: formState.organisation.trim() === '' ? 'Organisation is required' : '',
      message: formState.message.trim() === '' ? 'Message is required' : '',
    };

    setFormState(prevState => ({ ...prevState, errors }));
    return Object.values(errors).every(error => error === '');
  };

  const sendEmail = async e => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setFormState(prevState => ({ ...prevState, loading: true })); 
        await emailjs.sendForm('service_k7yar8i', 'template_dm5wmqn', e.target, 'h-QrtZQ3fBDhhtPSm');
        setFormState({
          fullName: '',
          phoneNumber: '',
          email: '',
          organisation: '',
          message: '',
          errors: {
            fullName: '',
            phoneNumber: '',
            email: '',
            organisation: '',
            message: '',
          },
          successMessage: 'Email sent successfully!',
          loading: false,
        });
      } catch (error) {
        console.error('Error sending email:', error);
        setFormState(prevState => ({
          ...prevState,
          successMessage: 'Failed to send email. Please try again later.',
          loading: false, 
        }));
      }
    }
  };

  return (
    <div className="mt-24 px- mx-auto">
      <section className="mb-32 text-gray-800">
        <div className="flex flex-wrap">
          <div className="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
            <h2 className="text-3xl font-b old mb-6">Contact us</h2>
            <p className="text-gray-500 mb-6">
              We are experts in software development, and our work cannot go unnoticed as we have and still dedicate ourselves to giving out Technology/Software solutions that are affordable, customizable, innovative, and easy to use, as well as 100% satisfaction to our clients.
            </p>
            <p className="text-gray-500 mb-2">Area 47, sector 5, Near Zodiak Broadcasting Station</p>
            <p className="text-gray-500 mb-2">+265 884 675 719</p>
            <p className="text-gray-500 mb-2">info@ebsolutionsmw.com</p>
            {/* <a href="/Wp Code" className="justify-self-center">
              <button className="justify-self-center transition duration-300 bg-transparent hover:bg-green-500 text-green-700 hover:text-white py-1 px-3 border border-green-700 hover:border-transparent rounded-full">
                <i className="fab fa-whatsapp"></i>
              </button>
            </a> */}
            <a href="tel: +265884675719" className="justify-self-center">
              <button className="ml-3 justify-self-center transition duration-300 bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-1 px-3 border border-blue-700 hover:border-transparent rounded-full">
                <i className="fa-solid fa-phone"></i>
              </button>
            </a>
            <a href="mailto: info@ebsolutionsmw.com" className="justify-self-center">
              <button className="ml-3 justify-self-center transition duration-300 bg-transparent hover:bg-slate-500 text-slate-700 hover:text-white py-1 px-3 border border-slate-700 hover:border-transparent rounded-full">
                <i className="fa-regular fa-envelope"></i>
              </button>
            </a>
          </div>
          <div className="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
            <form className="contact_form" onSubmit={sendEmail}>
            <h3 className='text-lime-500' id="SuccessMessage">{formState.successMessage}</h3>
              {formState.loading && <p>Please wait, sending email...</p>} 
              <br />
              <div className="form-group mb-6">
                <input
                  name="fullName"
                  type="text"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="fullname"
                  placeholder="Full Name"
                  value={formState.fullName}
                  onChange={e => setFormState(prevState => ({ ...prevState, fullName: e.target.value }))}
                />
                <span className="text-red-500">{formState.errors.fullName}</span>
              </div>
              <div className="form-group mb-6">
                <input
                  name="phoneNumber"
                  type="text"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  value={formState.phoneNumber}
                  onChange={e => setFormState(prevState => ({ ...prevState, phoneNumber: e.target.value }))}
                />
                <span className="text-red-500">{formState.errors.phoneNumber}</span>
              </div>
              <div className="form-group mb-6">
                <input
                  name="email"
                  type="email"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="email"
                  placeholder="Email address"
                  value={formState.email}
                  onChange={e => setFormState(prevState => ({ ...prevState, email: e.target.value }))}
                />
                <span className="text-red-500">{formState.errors.email}</span>
              </div>
              <div className="form-group mb-6">
                <input
                  name="organisation"
                  type="text"
                  className="form-control block w-full px-3 py-1.5
                  text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="organisation"
                  placeholder="Organisation"
                  value={formState.organisation}
                  onChange={e => setFormState(prevState => ({ ...prevState, organisation: e.target.value }))}
                />
                <span className="text-red-500">{formState.errors.organisation}</span>
              </div>
              <div className="form-group mb-6">
                <textarea
                  name="message"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="message"
                  rows="3"
                  placeholder="Message"
                  value={formState.message}
                  onChange={e => setFormState(prevState => ({ ...prevState, message: e.target.value }))}
                ></textarea>
                <span className="text-red-500">{formState.errors.message}</span>
              </div>
              <button
                type="submit"
                className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
