import React from 'react';
import './App.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { HashRouter, Route, Switch } from 'react-router-dom'

import Navbar from './Components/Navbar';
import ScrollBack from './Components/ScrollBack'
import RequestPopUp from './Components/demo-popup'
import NavScrollBack from './Components/NavScrollBack'
import PageNotFound from './Views/404'

import Contact from './Views/Contact';
import Home from './Views/Home';
import About from './Views/About'
import EbAccounting from './Views/EasyBooks_Accounting';
import EbAutomation from './Views/EasyBooks_ERP';
import EbIMERIS from './Views/EasyBooks_IMERIS';
import SoftwareDevelopment from './Views/SoftwareDevelopment';
import BRP from './Views/BPR';
import Clients from './Views/Clients';
import SuccessStoties from './Views/SuccessStoties';
import Team from './Views/Team';

function App() {
  
  return (
    <div id='app' className='bg-white font-serif'>
      <HashRouter>

        <Navbar />
        <ScrollBack />
        <RequestPopUp />
        <NavScrollBack/>

        <Switch>

          <Route path='/' component={Home} exact />
          <Route path='/About' component={About} />
          <Route path='/Contact' component={Contact} />
          <Route path='/EasyBooks_Accounting' component={EbAccounting} />
          <Route path='/EasyBooks_ERP' component={EbAutomation} />
          <Route path='/EasyBooks_IMERIS' component={EbIMERIS} />
          <Route path='/SoftwareDevelopment' component={SoftwareDevelopment} />
          <Route path='/BRP' component={BRP} />
          <Route path='/Clients' component={Clients} />
          <Route path='/SuccessStories' component={SuccessStoties} />
          <Route path='/Team' component={Team} />
          <Route path='*' component={PageNotFound} />

        </Switch>

      </HashRouter>
    </div>

  );
}

export default App;
