import React from 'react'
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function SuccessStoties() {
  return (
    <div className=" ">
    <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
         <div className="animate-pulse animate-once animate-ease-out  inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
           <svg
             className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
             viewBox="0 0 100 100"
             fill="#010066"
             preserveAspectRatio="none slice"
           >
             <path d="M50 0H100L50 100H0L50 0Z" />
           </svg>
           <img
             className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
             src="Assets/stacking-wood.jpg"
             alt="About"
           />
   
         </div>
         <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
           <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">
          
             <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
               Success Stories
       
             </h2>
          <Link to='/'>
             Home
          </Link>
          /Success Stories
           
           </div>
         </div>
       </div>
       
     <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
 
         <div className="w-fullflex flex-col justify-left">
             <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left ">Award Winning Company</h1>
 
             <p className="font-normal text-base leading-6 text-gray-600 text-left">
             EasyBooks Solutions is an award-winning software development company based in Malawi. 
             Since its establishment in 2019, the company has been providing innovative software solutions
              to clients in various industries, including healthcare, education, finance, and government. </p>
         </div>
     </div>
 
     <div className=" flex lg:flex-row flex-col justify-between gap-4 pt-12 mt-18 md:py-12 md:px-48 py-9 px-2 animate-fade-up animate-once animate-ease-out">
     <div className="w-full lg:w-5/12 flex flex-col justify-center ">
         <img className='rounded' src='Assets/Catalog/SAMSON FIADO with HE.jpg' alt='About' />
         </div>       
         <div className="w-full lg:w-full flex flex-col justify-center ">
             <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">  2022 Presidential ZIKOMO Awards</h1>
             <p className="font-normal text-base leading-6 text-gray-600 ">
             EasyBooks Solutions receiving the Presidential Excellence Award from Dr Lazarus Chakwera, 
             the State President of the Republic of Malawi during the 2022 Presidential ZIKOMO Awards.</p>
         </div>
 
      
     </div>  

     <div className=" flex lg:flex-row flex-col justify-between gap-4 pt-12 mt-18 md:py-12 md:px-48 py-9 px-2 animate-fade-up animate-once animate-ease-out">
     <div className="w-full lg:w-5/12 flex flex-col justify-center ">
         <img className='rounded' src='Assets/Catalog/receives-the-award.jpg' alt='About' />
         </div>       
         <div className="w-full lg:w-full flex flex-col justify-center ">
             <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4"> ICTAM 2020 ICT Innovation Awards</h1>
             <p className="font-normal text-base leading-6 text-gray-600 ">
             EasyBooks Emerged Overall Winner in the Fintech Category with 'EasyBooks Accounting Software'
              a simple to use and affordable web-based accounting package 
             developed to manage financial data for small and medium sized institutions.</p>
         </div>
 
      
     </div>  
     <div className="flex lg:flex-row flex-col justify-between gap-4 pt-12 mt-18 md:py-12 md:px-48 py-9 px-2 animate-fade-up animate-once animate-ease-out">
     <div className="w-full lg:w-5/12 flex flex-col justify-center ">
         <img className='rounded' src='Assets/Catalog/Environmental Innovation Award.jpg' alt='About' />
 
         </div>
         <div className="w-full lg:w-full flex flex-col justify-center ">
             <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">ICTAM 2016 ICT Innovation Awards
        </h1>
             <p className="font-normal text-base leading-6 text-gray-600 ">
             EasyBooks Solutions receiving the Best Environmental Innovation Award (He designed a working model of Smart Waste Bin System that alerts relevant personnel on the levels of wastes in waste bins) from the then Min. of Information, Honorable Malison Ndau at ICT Association of Malawi Innovation Awards.
        </p>
         </div>
 
 
 
        
     </div> 
     <div className="flex lg:flex-row flex-col justify-between gap-4 pt-12 mt-18 md:py-12 md:px-48 py-9 px-2 animate-fade-up animate-once animate-ease-out">
     <div className="w-full lg:w-5/12 flex flex-col justify-center ">
         <img className='rounded' src='Assets/Catalog/19678145593_94807c59ab_o.jpg' alt='About' />
 
         </div>
         <div className="w-full lg:w-full flex flex-col justify-center ">
             <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">2015 Spirit of Tech-I Award</h1>
             <p className="font-normal text-base leading-6 text-gray-600 ">
             EasyBooks Solutions receiving a Spirit of Tech-I Award by US Department of State and American Association for the Advancement of Science in Kenya at the Global 
             Entrepreneurship Summit and the keynote Speaker was the then US President, Mr. Barack Obama.</p>
         </div>
 
 
 
        
     </div>   

     <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
 
 <div className="w-fullflex flex-col justify-left">
     <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left ">Philanthropy</h1>

     <p className="font-normal text-base leading-6 text-gray-600 text-left">
     Philanthropy in Malawi is an important aspect of the country's development,
      with many organizations and individuals working towards social, 
      economic, and environmental sustainability and EasyBooks Solutions is at the forefront Working hand in hand with institutions.</p>
 </div>
</div>


<div className=" flex lg:flex-row flex-col justify-between gap-4 pt-12 mt-18 md:py-12 md:px-48 py-9 px-2 animate-fade-up animate-once animate-ease-out">
    
 <div className="w-full lg:w-full flex flex-col justify-center ">
     <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4"> Firm eases mobility challenges for Parish</h1>
     <p className="font-normal text-base leading-6 text-gray-600 ">
     EasyBooks Solutions donating a motor bike to Lingidzi Anglican Diocese of Southern Malawi.
     The Event, which took place at the Anglican Diacese Headquarters in Limbe, 
      was also attended by Bishop Alinafe Kalemba of the Anglican Diocese of the southern Malawi.
</p>
 </div>

 <div className="w-full lg:w-5/12 flex flex-col justify-center ">
 <img className='rounded' src='Assets/Catalog/DSC_4197.jpg' alt='About' />
 </div>   
</div>  
<div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">
                  
                  <div className="w-full lg:w-2/6 flex flex-col justify-center ">
                          <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Join Us!</h1>
                          <ul className="list-none mt-6">
                                <li className="py-2">
                                  <div className="flex items-center">
                                    <div>
                                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                                        <i className="fas fa-fingerprint"></i>
                                      </span>
                                    </div>
                                    <div>
                                      <h4 className="text-gray-600">
                                      Secure
                                      </h4>
                                    </div>
                                  </div>
                                </li>
                                <li className="py-2">
                                  <div className="flex items-center">
                                    <div>
                                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                                        <i className="fab fa-html5"></i>
                                      </span>
                                    </div>
                                    <div>
                                      <h4 className="text-gray-600">Amazing Code Base</h4>
                                    </div>
                                  </div>
                                </li>
                                <li className="py-2">
                                  <div className="flex items-center">
                                    <div>
                                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-slate-200 mr-3">
                                        <i className="far fa-paper-plane"></i>
                                      </span>
                                    </div>
                                    <div>
                                      <h4 className="text-gray-600">Dynamic components</h4>
                                    </div>
                                  </div>
                                </li>
                              </ul>
              
                              <br/>
                       <Link to="/Contact" className="justify-self-center ml-10">
              
              <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
              Start Now <i className="fa-solid fa-arrow-right"></i>
              </button>  
              </Link>
                
                          
                                  </div>    
              
                                  <div className="w-full lg:w-2/6  justify-left ">
                   <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Win.jpg' alt='dev'/>   
                          
                                  </div>
                  </div>
     <Footer />
 </div>
  )
}

export default SuccessStoties