import React from 'react';
import Footer from '../Components/footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function About() {
  return (
    <div className="">
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
        <div className="animate-pulse animate-once animate-ease-out inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="#010066"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="Assets/AboutUs.jpg"
            alt="About"
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Discover about us
            </h2>
            <Link to='/'>Home</Link> / About
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-8 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
        <div className="w-full flex flex-col justify-left">
          <h1 className="text-3xl lg:text-3xl font-bold leading-9 text-blue-900 pb-4 text-left">About Us</h1>
          <p className="font-normal text-base leading-6 text-gray-600 text-left">
            A Malawian Technology start-up duly registered with Malawi Government. Our expertise in software development cannot go unnoticed as we have and still dedicate ourselves in giving out Technology/Software solutions which are affordable, Customizable, innovative, and easy to use as well as 100% satisfaction to our clients. EasyBooks Accounting Software, one of our products, has won both local and international awards and has been declared ideal for Small and Medium Enterprises/Institutions.
          </p>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12 mt-18 md:py-12 md:px-28 py-9 px-4 animate-fade-up animate-once animate-ease-out">
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <img src='Assets/about-us.webp' alt='About' />
        </div>
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Our Vision</h1>
          <p className="font-normal text-base leading-6 text-gray-600">
            To simplify business operations for both corporate and SMEs across Malawi and Africa through ICT innovations.
          </p>
        </div>
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Our Mission</h1>
          <p className="font-normal text-base leading-6 text-gray-600">
            To provide innovative, simple and customizable cutting-edge business systems to both corporate and SMEs on both local and international market.
          </p>
        </div>
     
      </div>

      
      <div className="flex lg:flex-row flex-col justify-center   gap-4 pt-8 mt-18 md:py-8 md:px-36 py-9 px-8 animate-fade-up animate-once animate-ease-out ">

<div className="w-full lg:w-2/6 flex flex-col justify-center ">
  <h1 className="text-2xl lg:text-2xl font-bold leading-9 text-blue-900 pb-4">Our Core Values</h1>
  <ul className="list-none mt-6">
    <li className="py-2">
      <div className="flex items-center">
        <div>
          <h4 className="text-gray-600">
          Innovation
          </h4>
        </div>
      </div>
    </li>
    <li className="py-2">
      <div className="flex items-center">
        <div>
          <h4 className="text-gray-600">Professionalism</h4>
        </div>
      </div>
    </li>
    <li className="py-2">
      <div className="flex items-center">
        <div>
          <h4 className="text-gray-600">Client-Centricity</h4>
        </div>
      </div>
    </li>   
     <li className="py-2">
      <div className="flex items-center">
        <div>
          <h4 className="text-gray-600">Integrity</h4>
        </div>
      </div>
    </li>
  </ul>
  <br />
  <Link to="/Contact" className="justify-self-center ml-0">

    <button className="justify-self-center bg-transparent hover:bg-blue-800 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
      Start Now <i className="fa-solid fa-arrow-right"></i>
    </button>
  </Link>


</div>

<div className="w-full lg:w-2/6  justify-left ">
  <img className='rounded animate-fade-up animate-once animate-ease-out' src='./Assets/Talk.jpg' alt='dev' />

</div>
</div>
    

      <Footer />
    </div>
  );
}

export default About;
