import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="flex items-center justify-between bg-white border-b border-gray-400 py-6 px-4 sticky top-0 z-50">

      <Link to="/">
        <img src="logo-dark.png" alt="Comp-logo" className="h-10  " />
      </Link>

      <nav>
        <section className="MOBILE-MENU flex lg:hidden px-4">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px] ">
              <li className="border-b border-gray-400 my-8 uppercase ">
                <Link  onClick={() => setIsNavOpen(false)} to="/">Home</Link>
              </li>
              <div className="group my-8 inline-block relative">
                <button>
                  <span className="border-b border-gray-400 my-8 uppercase">Products <i className="fa-solid fa-caret-down text-slate-300"></i></span>
                </button>
                <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block z-10">
                  <li>
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/EasyBooks_Accounting">EasyBooks Accounting</Link>
                  </li>
                  <li>
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/EasyBooks_ERP">EasyBooks ERP</Link>
                  </li>
                  <li>
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/EasyBooks_IMERIS">EasyBooks IMERIS</Link>
                  </li>
                </ul>
              </div>

              <div className="group my-8 inline-block relative">
                <button>
                  <span className="border-b border-gray-400 my-8 uppercase">Services <i className="fa-solid fa-caret-down text-slate-300"></i></span>
                </button>
                <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block z-10">
                  <li>
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-2 block whitespace-no-wrap border-b border-gray-200" to="/SoftwareDevelopment">Software Development</Link>
                  </li>
                  <li>
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-2 block whitespace-no-wrap" to="/BRP">Business Process Reengineering</Link>
                  </li>
                </ul>
              </div>

              <div className="group my-8 inline-block relative">
                <button>
                  <span className="border-b border-gray-400 my-8 uppercase">About Us <i className="fa-solid fa-caret-down text-slate-300"></i></span>
                </button>
                <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block z-10">
                  <li className="">
                    <Link  onClick={() => setIsNavOpen(false)} className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/About">Overview</Link>
                  </li>
                  <li className="">
                    <Link  onClick={() => setIsNavOpen(false)} className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/SuccessStories">Success Stories</Link>
                  </li>
                  <li className="">
                    <Link  onClick={() => setIsNavOpen(false)} className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200" to="/Team">The Team</Link>
                  </li>
                </ul>
              </div>

              <li className="border-b border-gray-400 my-8 uppercase">
                <Link onClick={() => setIsNavOpen(false)} to="/Clients">Clients</Link>
              </li>

              <li className="border-b border-gray-400 my-8 uppercase">
                <Link  onClick={() => setIsNavOpen(false)} to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex text-sm mr-4">

          <li>
            <Link to="/" className=" hover:text-blue-900 underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit ">Home</Link>
          </li>


          <div className="group inline-block relative">
            <button>
              <span className="mr-1">Products <i className="fa-solid fa-caret-down text-slate-300"></i></span>
            </button>
            <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block">
              <li className="">
                <Link
                  className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/EasyBooks_Accounting"
                >EasyBooks Accounting</Link>
              </li>
              <li className="">
                <Link
                  className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/EasyBooks_ERP"
                >EasyBooks ERP</Link>
              </li>
              <li className="">
                <Link
                  className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/EasyBooks_IMERIS"
                >EasyBooks IMERIS</Link>
              </li>

            </ul>
          </div>
          <div className="group inline-block relative">
            <button>
              <span className="mr-1">Services <i className="fa-solid fa-caret-down text-slate-300"></i></span>
            </button>
            <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block">
              <li className="">
                <Link
                  className="bg-white text-sm hover:text-blue-900 py-4 px-2 block whitespace-no-wrap border-b border-gray-200"
                  to="/SoftwareDevelopment"
                >Software Developement</Link>
              </li>
              <li className="">
                <Link
                  className=" bg-white text-sm hover:text-blue-900 py-4 px-2 block whitespace-no-wrap"
                to="/BRP">Business Process Reengineering
                </Link>
              </li>
            </ul>
          </div>
          <div className="group inline-block relative">
            <button>
              <span className="mr-1">About Us <i className="fa-solid fa-caret-down text-slate-300"></i></span>
            </button>
            <ul className="absolute w-48 h-48 hidden text-gray-700 pt-4 group-hover:block">
              <li className="">
                <Link
                  className="bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/About"
                >Overview</Link>
              </li>
              <li className="">
                <Link className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/SuccessStories"
                >Success Stories</Link>
              </li>
              <li className="">
                <Link
                  className=" bg-white text-sm hover:text-blue-900 py-4 px-4 block whitespace-no-wrap border-b border-gray-200"
                  to="/Team"
                >The Team</Link
                >
              </li>

            </ul>
          </div>
          <li>
            <Link to="/Clients" className="hover:text-blue-900 underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit">Clients</Link>
          </li>
          <li>
            <Link to="/Contact" className="hover:text-blue-900 underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit">Contact</Link>
          </li>

        </ul>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  );
}
