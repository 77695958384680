import { Link } from "react-router-dom";   

export default function Header() {

    return (
        <div className="mb-16 ">

            <dh-component >
                <div>
                    <div className="container flex justify-center mx-auto pt-16 animate-fade-up ">
                        <div>
                            <h1 className="xl:text-3xl text-3xl text-center text-blue-900 font-extrabold pb-6 mx-auto">Our Products</h1>
                        </div>
                    </div>
                    <div className="w-full  px-10 pt-10 animate-fade-up">
                        <div className="container mx-auto">
                            <div role="list" className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                                <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                    <div className="rounded overflow-hidden shadow-md bg-white  h-80">
                                        <div className="absolute -mt-10 w-full flex justify-center">
                                            <div className="h-20 w-20">
                                                <img src="Assets/accouting_icon.png" alt="" className=" object-cover h-full w-full " />
                                            </div>
                                        </div>
                                        <div className="px-6 mt-16 text-center">
                                            <h1 className="font-bold text-gray-900 text-2xl text-center mb-1">EasyBooks Accounting</h1>
                                            <p className="text-center text-gray-600 text-base pt-3 font-normal">
                                                Our user-friendly software simplifies accounting tasks,
                                                making it easy for businesses of all sizes to stay organized and focused on growth.
                                            </p>
                                            <Link to="/EasyBooks_Accounting" className="text-center underline underline-offset-4">
                                                Learn More...
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div role="listitem" className="xl:w-1/3 lg:mx-3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                    <div className="rounded overflow-hidden shadow-md bg-white h-80">
                                        <div className="absolute -mt-10 w-full flex justify-center">
                                            <div className="h-20 w-20">
                                                <img src="Assets/automation_icon.png" alt="Web dev" className="object-cover h-full w-full " />
                                            </div>
                                        </div>
                                        <div className="px-6 mt-16 text-center">
                                            <h1 className="font-bold text-2xl text-center mb-1">EasyBooks ERP</h1>
                                            <p className="text-center text-gray-600 text-base pt-3 font-normal">
                                                Our powerful workflow management system is designed to enhance efficiency
                                                and collaboration within your Organization.
                                            </p>
                                            <Link to="/EasyBooks_ERP" className="text-center underline underline-offset-4">
                                                Learn More...
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                    <div className="rounded overflow-hidden shadow-md bg-white h-80">
                                        <div className="absolute -mt-10 w-full flex justify-center">
                                            <div className="h-20 w-20">
                                                <img src="Assets/e-health_icon.png" alt="Consulting" className=" object-cover h-full w-full" />
                                            </div>
                                        </div>
                                        <div className="px-6 mt-16 text-center">
                                            <h1 className="font-bold text-2xl text-center mb-1">EasyBooks IMERIS</h1>
                                            <h3><b>(E-Health)</b></h3>
                                            <p className="text-center text-gray-600 text-base pt-3 font-normal">
                                                Our E-Health solution is tailored to meet the unique needs of medical
                                                professionals, offering advanced tools for patient records, and billing.
                                            </p>
                                            <Link to="/EasyBooks_IMERIS" className="text-center underline underline-offset-4">
                                                Learn More...
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>     
                   <div>
                    <hr />
                    <div className="container flex justify-center mx-auto pt-16 animate-fade-up ">
                        <div>
                            <h1 className="xl:text-3xl text-3xl text-center text-blue-900 font-extrabold pb-6 mx-auto">Our Services</h1>
                        </div>
                    </div>
                    <div className="w-full  px-10 pt-10 animate-fade-up">
                        <div className="container mx-auto">
                            <div role="list" className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                                <div role="listitem" className="lg:ml-10 xl:w-1/2 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                    <div className="rounded overflow-hidden shadow-md bg-white  h-80">
                                        <div className="absolute -mt-10 w-full flex justify-center">
                                            <div className="h-20 w-20">
                                                <img src="Assets/web-developer.png" alt="Web Dev" className=" object-cover h-full w-full " />
                                            </div>
                                        </div>
                                        <div className="px-6 mt-16 text-center">
                                            <h1 className="font-bold text-gray-900 text-2xl text-center mb-1">Software Development</h1>
                                            <p className="text-center text-gray-600 text-base pt-3 font-normal">Whether you need a robust web solution able to support hundreds of thousands of concurrent users, or an advanced mobile app set to transform customer experience.</p>

                                            <Link to="/Software-Development" className="text-center underline underline-offset-4 ">
                                                Learn More...
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                                <div role="listitem" className="lg:mr-10 xl:w-1/2 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                    <div className="rounded overflow-hidden shadow-md bg-white h-80">
                                        <div className="absolute -mt-10 w-full flex justify-center">
                                            <div className="h-20 w-20">
                                                <img src="Assets/Image-Consulting.png" alt="Consulting" className=" object-cover h-full w-full" />
                                            </div>
                                        </div>
                                        <div className="px-6 mt-16 text-center">
                                            <h1 className="font-bold text-2xl text-center mb-1">Business Process Re-Engineering</h1>
                                            <p className="text-center text-gray-600 text-base pt-3 font-normal ">
                                            Redesigning of existing business processes to achieve substantial improvements in efficiency,
                                            productivity, and overall performance. </p>
                                            <Link to="BRP" className="text-center underline underline-offset-4 ">
                                               Learn More...
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white py-24 sm:py-10 animate-fade-up">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-center text-2xl	 font-semibold leading-8 text-blue-900">Clients</h2>
                        <div className=" mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                            <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 shadow-lg grayscale transition duration-300 hover:filter-none" src="Assets/Clients/Clinton_Health_Access_Initiative.png" alt="client" width="158" height="48" />
                            <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 shadow-lg grayscale transition duration-300 hover:filter-none" src="Assets/Clients/ALIDZIWA-WEB-LOGO.png" alt="client" width="158" height="48" />
                            <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 shadow-lg grayscale transition duration-300 hover:filter-none" src="Assets/Clients/Ministry of Health.png" alt="client" width="158" height="48" />
                            <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 shadow-lg grayscale transition duration-300 hover:filter-none" src="Assets/Clients/Lake Malawi Anglican Univ.png" alt="client" width="158" height="48" />
                            <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 shadow-lg grayscale transition duration-300 hover:filter-none" src="Assets/Clients/Speed Fitment.jpg" alt="client" width="158" height="48" />

                        </div>
                        <div className="grid justify-items-stretch mt-10">
                            <Link to="/Clients" className="justify-self-center">

                                <button className="justify-self-center transition duration-300 bg-transparent hover:bg-blue-900 text-blue-700  hover:text-white py-1 px-4 border border-blue-900 hover:border-transparent rounded">
                                    View All <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </dh-component>
        </div>
    );
};