import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function MessageDialog() {
    return (
        <>
            <Link to='/Contact'
                className={`fixed bottom-2 right-5 z-10 transition-opacity duration-300 ease-in-out rounded-full p-2 bg-blue-600 text-white hover:bg-blue-700 hover:opacity-90`}
               
            >
                <i className="fa fa-paper-plane"></i> Send Enquiry 
            </Link>

        </>
    )
}
