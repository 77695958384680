import React from 'react';
import Footer from '../Components/footer'
import { Link } from 'react-router-dom/cjs/react-router-dom';

function Team() {
    return (
        <div>
            <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 border-b border-gray-400 bg-white">
                <div className="animate-pulse animate-once animate-ease-out inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                    <svg
                        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
                        viewBox="0 0 100 100"
                        fill="#010066"
                        preserveAspectRatio="none slice"
                    >
                        <path d="M50 0H100L50 100H0L50 0Z" />
                    </svg>
                    <img
                        className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
                        src="Assets/Catalog/TEAM.jpg"
                        alt="Team"
                    />
                </div>
                <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
                    <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5 animate-fade-up animate-once animate-ease-out">
                        <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                            Our Team
                        </h2>
                        <Link to='/'>Home</Link> / Team
                    </div>
                </div>
            </div>
            <section className="bg-white light:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                    <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 light:text-white">Our Team</h2>
                    </div>

                    <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M4.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Samson Fiado</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">CEO & Software Developer</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Samson is instrumental in shaping the business and technical strategies at EasyBooks Solutions.</p>
                            </div>
                        </div>

                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M1.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Anna Msukwa</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">Systems Support & Client Relationship Officer</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Anna manages system operations and fosters strong client relationships.</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M3.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Elias Mgala</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">Software Developer</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Elias plays a key role in driving the technical strategy of EasyBooks.</p>
                            </div>
                        </div>

                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M5.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Wakisa Munthali</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">Software Developer</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Wakisa actively contributes to the technical strategy of EasyBooks.</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M6.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Vincent Mazenga</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">Marketing & Sales Officer</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Vincent shapes the technical strategy of the EasyBooks platform and brand.</p>
                            </div>
                        </div>

                        <div className="items-center bg-gray-50 rounded-lg shadow sm:flex light:bg-gray-800 light:border-gray-700">
                            <a >
                                <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src="Assets/Team/M2.jpg" alt="Bonnie Avatar" />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 light:text-white">
                                    <a >Marie Solange</a>
                                </h3>
                                <span className="text-gray-500 light:text-gray-400">Marketing & Sale Lead</span>
                                <p className="mt-3 mb-4 font-light text-gray-500 light:text-gray-400">Marie drives the technical and strategic initiatives of the EasyBooks platform and brand.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />

        </div>


    )
}

export default Team